import '../assets/css/nds.css';
import React, { Component } from "react"
import conIcon from "../images/cong-icon.svg"
import Layout from "../components/layout"
import { connect } from 'react-redux';
import * as actionCreators from '../store/actions';

import sc from "../images/PA/sclogo.svg"
import dowc from "../images/PA/dowclogo.png"
import kf from "../images/PA/keyfetchlogo.jpg"


class ndsend extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type:null
        };
       
      }
    
    componentDidMount() {
        let qs = {};
        window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, async function(m,key,value) {
            qs[key] = decodeURI(value);
        });
        this.setState({ type: qs.type });
        this.nameInput.focus();
    }
    render() {
        const { type } = this.state;
        return (
            <Layout layoutType="nds" >
                <input className="hidox" ref={(input) => { this.nameInput = input; }} />
                <section className="congratulation-section ndsec">
                    <div className="custom-container main-congratulation-container">
                        <div className="body-content congratulation-content ">
                            <div className="congratulation-container ">
                            {/* {type=='SC'?<img src={sc} alt="Service Contract"  />:''}
                            {type=='DOWC'?<img src={dowc} alt="DOWC"  />:''}
                            {type=='Keyfetch'?<img src={kf} alt="Keyfetch"  />:''} */}
                            <br /><br /> 
                            <h4>Thank you for signing up with the fastest growing service contract administrator in the US!</h4>
                        </div>
                    </div>
                </div> 
                </section>
            </Layout>
        );
    }
}

const mapStateToProps = state => {
    return {
        step2response: state.register.step2response,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        clearAllSteps: () => dispatch(actionCreators.ClearAllSteps())
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(ndsend);
